var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tilitys-selaa" },
    [
      _c("ViewSelaaTopBarBase", {
        attrs: {
          "pikahaun-placeholder-teksti": _vm.$t(
            "common:settlement.quickSearch"
          ),
          "vuex-module-name": _vm.vuexModuleName,
        },
        on: {
          "open-search-panel": _vm.openSearchPanel,
          "close-search-panel": _vm.closeSearchPanel,
        },
      }),
      _c("TransitionSlidePanel", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.searchPanelOpen,
                expression: "searchPanelOpen",
              },
            ],
            staticClass: "pa-1 primary darken-1 shadow--inset",
          },
          [
            _c("FormSearchTilitys", {
              model: {
                value: _vm.searchTerms,
                callback: function ($$v) {
                  _vm.searchTerms = $$v
                },
                expression: "searchTerms",
              },
            }),
          ],
          1
        ),
      ]),
      _c("ViewSelaaToolbar", {
        attrs: {
          "lataa-csv-btn-config": {
            csvData: _vm.csvData,
            csvLabels: _vm.csvLabels,
            csvTitle: _vm.csvTitle,
          },
          "vuex-module-name": _vm.vuexModuleName,
          "item-partitiivi": _vm.$t("common:settlement.ofAccounting"),
        },
      }),
      _c("v-data-table", {
        staticClass: "v-data-table--striped",
        attrs: {
          headers: _vm.tableHeaders,
          items: _vm.items,
          "hide-default-footer": _vm.items.length < 10,
          "footer-props": {
            itemsPerPageOptions: [10, 25, 50, 100],
          },
          "multi-sort": true,
          "server-items-length": _vm.itemsTotal,
          options: _vm.pagination,
          loading: _vm.loading,
          "loading-text": "",
          "no-data-text": "",
          dense: _vm.tiivisNakyma,
        },
        on: {
          "update:options": function ($event) {
            _vm.pagination = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "progress",
            fn: function () {
              return [
                _c("v-progress-linear", {
                  attrs: { color: "primary", indeterminate: "" },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "item",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "TableRow",
                  {
                    attrs: { clickable: false, data: item, fade: _vm.loading },
                  },
                  [
                    _c(
                      "td",
                      { staticClass: "text-no-wrap" },
                      [
                        _c("v-tooltip", {
                          attrs: {
                            top: "",
                            disabled: !item.tilityserittelyt.length,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    item.ikoni_ja_vari
                                      ? _c("StatusIcon", {
                                          attrs: {
                                            icon: item.ikoni_ja_vari.ikoni,
                                            size: _vm.tiivisNakyma
                                              ? "small"
                                              : "medium",
                                            "hover-icon": item.tilityserittelyt
                                              .length
                                              ? "file-download"
                                              : "",
                                            "bg-color": item.ikoni_ja_vari.vari,
                                            "event-listener": on,
                                          },
                                          on: {
                                            click: function ($event) {
                                              item.tilityserittelyt.length
                                                ? _vm.lataaErittely(
                                                    item.id,
                                                    item.tilityserittelyt[0].id
                                                  )
                                                : function () {}
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                },
                              },
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "common:settlement.loadSpecification"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                    _c("td", { staticClass: "text-no-wrap" }, [
                      _vm._v(" " + _vm._s(item.id) + " "),
                    ]),
                    _c("td", { staticClass: "max-width text-wrap" }, [
                      _vm._v(" " + _vm._s(item.paamies.nimi)),
                      item.paamies.laskulaji
                        ? _c("span", [
                            _vm._v(", " + _vm._s(item.paamies.laskulaji)),
                          ])
                        : _vm._e(),
                    ]),
                    _c(
                      "td",
                      { staticClass: "text-no-wrap" },
                      _vm._l(item.tilityserittelyt, function (erittely, index) {
                        return _c("span", { key: index }, [
                          index !== 0 ? _c("span", [_c("br")]) : _vm._e(),
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.lataaErittely(item.id, erittely.id)
                                },
                              },
                            },
                            [_vm._v(_vm._s(erittely.nimi))]
                          ),
                        ])
                      }),
                      0
                    ),
                    _c(
                      "td",
                      { staticClass: "text-no-wrap" },
                      _vm._l(
                        item.tilityslasku_set,
                        function (tilityslasku, index) {
                          return _c(
                            "span",
                            { key: tilityslasku.id + index },
                            _vm._l(
                              tilityslasku.lasku.kirje_set,
                              function (kirje, idx) {
                                return _c("span", { key: kirje + idx }, [
                                  index !== 0
                                    ? _c("span", [_c("br")])
                                    : _vm._e(),
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.lataaLasku(
                                            tilityslasku.lasku.id,
                                            kirje
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(tilityslasku.lasku.nro))]
                                  ),
                                ])
                              }
                            ),
                            0
                          )
                        }
                      ),
                      0
                    ),
                    _c("td", { staticClass: "text-no-wrap" }, [
                      _vm._v(" " + _vm._s(item.tyyppi_display) + " "),
                    ]),
                    _c("td", { staticClass: "text-no-wrap text-right" }, [
                      _vm._v(" " + _vm._s(_vm.$date(item.luotu)) + " "),
                    ]),
                    _c("td", { staticClass: "text-no-wrap text-right" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.lahetetty ? _vm.$date(item.lahetetty) : "-"
                          ) +
                          " "
                      ),
                    ]),
                    _c("td", { staticClass: "text-no-wrap" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.tyyppi === "PM"
                              ? _vm.formatSumToFixed2(item.maksetaan)
                              : _vm.$t("common:settlement.seeSpecification")
                          ) +
                          " "
                      ),
                    ]),
                    _c("td", { staticClass: "text-no-wrap" }, [
                      _vm._v(" " + _vm._s(item.muistiinpanot) + " "),
                    ]),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _vm.pagesTotal > 1
        ? _c(
            "v-toolbar",
            { staticClass: "my-6 py-2 transparent", attrs: { flat: "" } },
            [
              _c("v-spacer"),
              _c("Pagination", {
                attrs: { length: _vm.pagesTotal, disabled: _vm.loading },
                model: {
                  value: _vm.pagination.page,
                  callback: function ($$v) {
                    _vm.$set(_vm.pagination, "page", $$v)
                  },
                  expression: "pagination.page",
                },
              }),
              _c("v-spacer"),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }