<template>
  <div class="tilitys-selaa">
    <!-- Top bar -->
    <ViewSelaaTopBarBase
      :pikahaun-placeholder-teksti="$t('common:settlement.quickSearch')"
      :vuex-module-name="vuexModuleName"
      @open-search-panel="openSearchPanel"
      @close-search-panel="closeSearchPanel"
    />

    <!-- Advanced search -->
    <TransitionSlidePanel>
      <div
        v-show="searchPanelOpen"
        class="pa-1 primary darken-1 shadow--inset"
      >
        <FormSearchTilitys v-model="searchTerms" />
      </div>
    </TransitionSlidePanel>

    <!-- Toolbar -->
    <ViewSelaaToolbar
      :lataa-csv-btn-config="{ csvData, csvLabels, csvTitle }"
      :vuex-module-name="vuexModuleName"
      :item-partitiivi="$t('common:settlement.ofAccounting')"
    />

    <!-- Show items as table -->
    <v-data-table
      class="v-data-table--striped"
      :headers="tableHeaders"
      :items="items"
      :hide-default-footer="items.length < 10"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50, 100],
      }"
      :multi-sort="true"
      :server-items-length="itemsTotal"
      :options.sync="pagination"
      :loading="loading"
      loading-text=""
      no-data-text=""
      :dense="tiivisNakyma"
    >
      <template v-slot:progress>
        <v-progress-linear
          color="primary"
          indeterminate
        />
      </template>
      <template v-slot:item="{ item }">
        <TableRow
          :clickable="(false)"
          :data="item"
          :fade="loading"
        >
          <td class="text-no-wrap">
            <v-tooltip
              top
              :disabled="!item.tilityserittelyt.length"
            >
              <template v-slot:activator="{ on }">
                <StatusIcon
                  v-if="item.ikoni_ja_vari"
                  :icon="item.ikoni_ja_vari.ikoni"
                  :size="tiivisNakyma ? 'small' : 'medium'"
                  :hover-icon="item.tilityserittelyt.length ? 'file-download' : ''"
                  :bg-color="item.ikoni_ja_vari.vari"
                  :event-listener="on"
                  @click="item.tilityserittelyt.length ? lataaErittely(item.id, item.tilityserittelyt[0].id) : () => {}"
                />
              </template>
              <template v-slot:default>
                <span>{{ $t('common:settlement.loadSpecification') }}</span>
              </template>
            </v-tooltip>
          </td>
          <td class="text-no-wrap">
            {{ item.id }}
          </td>
          <td class="max-width text-wrap">
            {{ item.paamies.nimi }}<span v-if="item.paamies.laskulaji">, {{ item.paamies.laskulaji }}</span>
          </td>
          <td class="text-no-wrap">
            <span
              v-for="(erittely, index) in item.tilityserittelyt"
              :key="index"
            ><span v-if="index !== 0"><br> </span><a @click="lataaErittely(item.id, erittely.id)">{{ erittely.nimi }}</a>
            </span>
          </td>
          <td class="text-no-wrap">
            <span
              v-for="(tilityslasku, index) in item.tilityslasku_set"
              :key="tilityslasku.id + index"
            >
              <span
                v-for="(kirje, idx) in tilityslasku.lasku.kirje_set"
                :key="kirje + idx"
              >
                <span v-if="index !== 0"><br> </span><a @click="lataaLasku(tilityslasku.lasku.id, kirje)">{{ tilityslasku.lasku.nro }}</a>
              </span>
            </span>
          </td>
          <td class="text-no-wrap">
            {{ item.tyyppi_display }}
          </td>
          <td class="text-no-wrap text-right">
            {{ $date(item.luotu) }}
          </td>
          <td class="text-no-wrap text-right">
            {{ item.lahetetty ? $date(item.lahetetty) : '-' }}
          </td>
          <td class="text-no-wrap">
            {{ item.tyyppi === 'PM' ? formatSumToFixed2(item.maksetaan) : $t('common:settlement.seeSpecification') }}
          </td>
          <td class="text-no-wrap">
            {{ item.muistiinpanot }}
          </td>
        </TableRow>
      </template>
    </v-data-table>

    <!-- Pagination -->
    <v-toolbar
      v-if="(pagesTotal > 1)"
      class="my-6 py-2 transparent"
      flat
    >
      <v-spacer />
      <Pagination
        v-model="pagination.page"
        :length="pagesTotal"
        :disabled="loading"
      />
      <v-spacer />
    </v-toolbar>
  </div>
</template>

<script>

import TransitionSlidePanel from '@/components/TransitionSlidePanel'
import {
  FormSearchTilitys,
  Pagination,
  ViewSelaaToolbar,
  ViewSelaaTopBarBase,
} from '@/components'
import ViewSelaaMixin from '@/mixins/ViewSelaaMixin'
import { TableHeadersTilitys } from '@/utils/tableHeaders'
import formatSumToFixed2 from '@/utils/filters/formatSumToFixed2'

export default {
  name: 'LaskutusSelaa',
  components: {
    TransitionSlidePanel,
    FormSearchTilitys,
    Pagination,
    ViewSelaaToolbar,
    ViewSelaaTopBarBase,
  },
  mixins: [ViewSelaaMixin],
  data () {
    return {
      tableHeaders: TableHeadersTilitys,
    }
  },
  computed: {
    csvLabels () {
      return {
          id: { title:  this.$sovellusIdOtsake },
          ...(!this.vainYksiPaamies && { paamies: { title: this.$t('common:creditor') } }),
          erittelyt: { title: this.$t('common:specifications') },
          laskut: { title: this.$t('common:invoices') },
          tyyppi: { title: this.$t('common:type') },
          tilityspaiva: { title: this.$t('common:settlementDate') },
          maksupaiva: { title: this.$t('common:paymentDate') },
          maksetaan: { title: this.$t('common:toBePaid') },
          muisiinpanot: { title: this.$t('common:notes') },
      }
    },
    csvData () {
      return this.items.map(item => {
        let erittelytStr = ''
        for (const erittely of item.tilityserittelyt) {
          erittelytStr += (erittelytStr ? ', ' : '') + erittely.nimi
        }

        let laskutStr = ''
        for (const tilityslasku of item.tilityslasku_set) {
          laskutStr += (laskutStr ? ', ' : '') + tilityslasku.lasku.nro
        }

        return {
          id: item.id,
          ...(!this.vainYksiPaamies && { paamies: `${item.paamies.nimi} (${item.paamies.id})` }),
          erittelyt: erittelytStr,
          laskut: laskutStr,
          tyyppi: item.tyyppi_display,
          tilityspaiva: item.luotu.substr(0, 10),
          maksupaiva: item.lahetetty,
          maksetaan: item.tyyppi === 'PM' ? formatSumToFixed2(item.maksetaan) : this.$t('common:settlement.seeSpecification'),
          muisiinpanot: item.muistiinpanot,
        }
      })
    },
    csvTitle () {
      return this.$t('common:settlement.settlementCSVTitle') + this.$dateFnsFormat(new Date(), 'YYYY-MM-DD-HHmm-ss')
    },
  },
  methods: {
    formatSumToFixed2 (summa) {
      return formatSumToFixed2(summa)
    },
    async lataaErittely (tilitysId, erittelyId) {
      try {
        const request = await this.$store.dispatch('tilitysSelaa/lataaErittely', {
          tilitysId,
          erittelyId,
        })

        if (request && !request.success) throw new this.$HttpError(request)

        this.$naytaTiedostonlataamisdialog({
          fileData: request.result.body,
          headers: request.result.headers,
        })
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      }
    },
    async lataaLasku (laskuId, kirjeId) {
      try {
        const request = await this.$store.dispatch('tilitysSelaa/lataaLasku', {
          laskuId,
          kirjeId,
        })

        if (request && !request.success) throw new this.$HttpError(request)

        this.$naytaTiedostonlataamisdialog({
          fileData: request.result.body,
          headers: request.result.headers,
        })
      } catch (e) {
        this.$oletusVirheenkasittely(e)
      }
    },
    lisaaViewKohtaisetHaunSuodattimet (query) {
      if (this.searchTerms.luotuAlkaen) query.append('luotu_after', this.searchTerms.luotuAlkaen)
      if (this.searchTerms.luotuPaattyen) query.append('luotu_before', this.searchTerms.luotuPaattyen)
      if (this.searchTerms.paamies && this.searchTerms.paamies.id) query.append('paamies', this.searchTerms.paamies.id)

      if (typeof this.searchTerms.tyyppi === 'object') {
        query = this.kasitteleMoniosuodatin(query, this.searchTerms.tyyppi)
      }

      return query
    },
  },
}

</script>

<style lang="scss" scoped>

</style>
